import React from "react"
import Section from "../components/page-section"

const HomePageIntegrations = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const {
    button_text,
    button_link,
    integrations_subtitle,
    integrations_title,
  } = primary

  return (
    <Section
      title={integrations_title || primary.title}
      subtitle={integrations_subtitle || primary.subtitle}
    >
      <div className="row justify-content-center">
        {items.map((item, i) => (
          <div className="col-md-3 mb-3" key={i}>
            <img
              alt="MoBerries ATS Integrations"
              src={item.logo.url}
              width="240"
              className="img-fluid"
            />
          </div>
        ))}
      </div>
      <div className="mt-4">
        <a className="cta" href={button_link.url}>
          {button_text}
        </a>
      </div>
    </Section>
  )
}

export default HomePageIntegrations
