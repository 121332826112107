import React from "react"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import { StaticQuery, graphql } from "gatsby"

const TiledContent = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle, anchor_id } = primary
  return (
    <section className="position-relative" id={anchor_id}>
      <div className="shape shape-1" />

      <div className="shape shape-2" />
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            {title?.raw[0] && (
              <h2 className="section-title">{RichText.asText(title.raw)}</h2>
            )}
            {subtitle?.raw[0] && (
              <div className="lead mb-5">
                {RichText.render(subtitle.raw, linkResolver, htmlSerializer)}
              </div>
            )}
          </div>
        </div>

        {items.map((item, i) => (
          <div
            key={i}
            className={cn(
              "row align-items-center justify-content-center mt-5",
              {
                "flex-row-reverse": i % 2 !== 0,
                "flex-row": i % 2 === 0,
              },
            )}
          >
            <div className="col-lg-5">
              {item.image.url && (
                <img
                  data-sal={i % 2 ? "slide-left" : "slide-right"}
                  data-sal-easing="ease-in-out"
                  data-sal-duration="1000"
                  className="img-fluid mb-4 mb-lg-0"
                  src={item.image.url}
                  alt={item.image.alt}
                />
              )}
            </div>
            <div className="col-lg-5 text-center text-md-left">
              <h4 className="mb-4">
                {item.title?.raw[0] && RichText.asText(item.title.raw)}
              </h4>
              {item.text?.raw[0] &&
                RichText.render(item.text.raw, linkResolver, htmlSerializer)}
              {item.cta_url && (
                <a className="cta" href={item.cta_url}>
                  {item.cta_button}
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query TiledQuery {
        shapeOne: file(name: { eq: "shape-1@2x" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        shapeTwo: file(name: { eq: "shape-2@2x" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const shapeOne = data.shapeOne.childImageSharp.fluid
      const shapeTwo = data.shapeTwo.childImageSharp.fluid

      return <TiledContent images={{ shapeOne, shapeTwo }} {...props} />
    }}
  />
)
