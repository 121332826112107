import React from "react"
import { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

const PartnersSection = ({ partners }) => {
  return (
    <div className="logo-slider slick-initialized slick-slider">
      <Swiper
        spaceBetween={10}
        slidesPerView={3}
        modules={[Autoplay]}
        breakpoints={{
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {partners.map((partner, i) => {
          const imgUrl =
            partner.image.localFile?.childImageSharp.fluid.src ||
            partner.image.url

          return (
            <SwiperSlide key={i}>
              <div
                key={i}
                className="logo-wrapper text-center px-4"
                tabIndex="-1"
              >
                <img
                  className="lazyload img-fluid"
                  src={imgUrl}
                  alt="partner"
                />
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {/* <div className="slick-list">
        <div className="slick-track">
          <Slider {...settingsSliderPartners}>
            {partners.map((partner, i) => (
              <div
                key={i}
                className="logo-wrapper text-center px-4"
                tabIndex="-1"
              >
                <img
                  className="lazyload img-fluid"
                  data-src={
                    partner.image.localFile?.childImageSharp.fluid.src ||
                    partner.image.url
                  }
                  alt="partner"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
    </div>
  )
}

export default PartnersSection
