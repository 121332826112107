import React from "react"
import TestimonialsSection from "../components/testimonials-section"

const TestimonialsCarousel = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { testimonial_carousel_title } = primary
  return (
    <TestimonialsSection
      testimonials={{
        title: testimonial_carousel_title || primary.title,
        items: items.map(item => ({
          ...item,
          text: item.testimonial_carousel_text || item.text,
        })),
      }}
    />
  )
}

export default TestimonialsCarousel
