import { useCallback, useState } from "react"
import debounce from "lodash.debounce"

export const useDebouncedState = (initialValue, timeout = 400) => {
  const [state, setState] = useState(initialValue)
  let [debouncedState, debouncedSetState] = useState(initialValue)
  debouncedSetState = useCallback(debounce(debouncedSetState, timeout), [
    timeout,
  ])
  return {
    state,
    debouncedState,
    setState: v => {
      setState(v)
      debouncedSetState(v)
    },
  }
}
