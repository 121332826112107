import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const Plans = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle, anchor_id } = primary
  return (
    <section className="py-6 text-center" id={anchor_id}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <h2 className="section-title">
              {title?.raw[0] && RichText.asText(title.raw)}
            </h2>
            {subtitle?.raw[0] && (
              <div className="lead mb-5">
                {RichText.render(subtitle.raw, linkResolver, htmlSerializer)}
              </div>
            )}
          </div>
        </div>
        <div className="row plans">
          {items.map((p, i) => (
            <div key={i} className="col-lg d-flex flex-column">
              <h4 className="text-muted">
                {p.title?.raw[0] && RichText.asText(p.title.raw)}
              </h4>
              <p>{p.subtitle}</p>
              <div className="text-blog font-weight-light display-4 mb-2">
                {p.price}
              </div>
              <p className="text-muted text-uppercase">{p.frequency}</p>
              <div className="list-unstyled mt-auto">
                {p.benefits?.raw[0] &&
                  RichText.render(p.benefits.raw, linkResolver, htmlSerializer)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Plans
