import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const CtaCentered = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const { cta_centered } = primary
  return (
    <section className="d-flex justify-content-center align-content-center">
      {cta_centered &&
        RichText.render(cta_centered.raw, linkResolver, htmlSerializer)}
    </section>
  )
}

export default CtaCentered
