import React from "react"
import FaqSection from "../components/faq-section"

const FAQVideo = ({ slice, locale }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const {
    faq_video_title,
    button_text,
    button_url,
    faq_video_subtitle,
  } = primary
  return (
    <FaqSection
      faq={{
        videos: items.map(item => ({
          video: item.video_number,
          link: item.tab_title,
        })),
        title: faq_video_title || primary.title,
        button: button_text,
        button_url: button_url?.url,
        subtitle: faq_video_subtitle || primary.subtitle,
      }}
      locale={locale}
      link="/companies/faq"
    />
  )
}

export default FAQVideo
