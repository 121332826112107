import React, { useState } from "react"
import { config } from "../utils/config"
import { camelCase, mapKeys, pickBy, size, isEmpty } from "lodash"
import axios from "axios"
import { isEmailValid, getUtmParams, getReferralUserId } from "../utils/helpers"
import track from "../utils/track"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import ReCAPTCHA from "react-google-recaptcha"

const title = {
  "en-us": "Post your jobs now for free and get applicants today!",
  "de-de": "Jetzt kostenlos Stellen ausschreiben und Bewerber erhalten!",
}

const subTitle = {
  "en-us":
    "Convince yourself of MoBerries' candidate quality. Create your MoBerries account today and test our recruiting solution 100% risk-free. You only pay in case of a successful hire.",
  "de-de":
    "Überzeugen Sie sich selbst von MoBerries Kandidatenqualität. Erstellen Sie noch heute Ihren MoBerries Account und testen Sie unsere Recruiting-Lösung 100% risikofrei. Sie zahlen erst bei einer erfolgreichen Einstellung.",
}

const button = {
  "en-us": "GET IN TOUCH",
  "de-de": "JETZT KOSTENLOSEN TEST STARTEN",
}

const message = {
  "en-us": (
    <span>
      Thank you for your interest! We will reach out to you soon. If you have
      any questions, please do not hesitate to contact us at{" "}
      <a href="mailto:business@moberries.com">business@moberries.com</a>{" "}
    </span>
  ),
  "de-de": (
    <span>
      Vielen Dank für Ihr Interesse! Wir werden zeitnah mit Ihnen in Kontakt
      treten. Sollten Sie Fragen haben, sind wir gerne für Sie unter{" "}
      <a href="mailto:business@moberries.com">business@moberries.com</a>{" "}
      erreichbar.
    </span>
  ),
}

const placeholder = {
  "en-us": {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Company E-mail",
    companyName: "Company Name",
    phone: "Phone Number",
  },
  "de-de": {
    firstName: "Vorname",
    lastName: "Nachname",
    email: "Geschäftliche E-Mail",
    companyName: "Firmenname",
    phone: "Telefonnummer",
  },
}

const requiredErrorMessage = {
  "en-us": "Value required",
  "de-de": "Eingabe ist erforderlich.",
}

const emailErrorMessage = {
  "en-us": "Value should be a valid email.",
  "de-de": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
}

const APIErrorMessage = {
  "en-us": "Please check your input.",
  "de-de": "Bitte Eingabe überprüfen.",
}

const buttonHint = {
  "en-us": (
    <div>
      If you have any questions do not hesitate to contact us: <br />
      +49 (0) 30 275 96 400
    </div>
  ),
  "de-de": (
    <div>
      Bei Fragen, rufen Sie uns gerne an:
      <br />
      +49 (0) 30 275 96 400
    </div>
  ),
}

const fields = ["firstName", "lastName", "email", "companyName", "phone"]

const mapFieldNames = value => {
  const mapKeyTo = {
    first_name: "firstName",
    last_name: "lastName",
    name: "companyName",
  }

  return mapKeys(value, (_, key) => {
    if (fields.includes(key)) return key
    if (mapKeyTo[key]) return mapKeyTo[key]
    return key
  })
}

const generateFieldNamesForValidation = serverFieldErrors => {
  const keys = Object.keys(mapFieldNames(serverFieldErrors))
  const fieldNames = keys.map(i => camelCase(i))
  return fieldNames
}

const getValidationErrors = values => {
  const missingFields = Object.keys(pickBy(values, isEmpty))
  return {
    required: missingFields,
    isEmailInvalid: !isEmailValid(values.email),
  }
}

const initialValidationErrors = {
  required: [],
  isEmailInvalid: false,
  fromAPI: [],
}

const ContactUs = ({ slice, locale }) => {
  const { primary } = slice
  const { anchor_id } = primary
  const [values, setValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    phone: "",
    recaptcha: "",
  })
  const [isSuccesfullySubmitted, setIsSuccesfullySubmitted] = useState(false)
  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors,
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setValidationErrors(initialValidationErrors)

    const { required, isEmailInvalid } = getValidationErrors(values)

    if (size(required) > 0 || isEmailInvalid) {
      setValidationErrors(validationErrors => ({
        ...validationErrors,
        required,
        isEmailInvalid,
      }))
      setIsLoading(false)
      return
    }

    let invitation_tracking

    const referralInviteUserId = getReferralUserId()
    const utmParams = getUtmParams()

    if (referralInviteUserId) {
      invitation_tracking = { userFrom: referralInviteUserId }
    }

    if (utmParams) {
      invitation_tracking = { ...invitation_tracking, ...utmParams }
    }
    const { firstName, lastName, email, companyName, phone, recaptcha } = values

    try {
      await axios.post(`${config.moberries.api.url}companies/`, {
        email,
        first_name: firstName,
        last_name: lastName,
        name: companyName,
        phone,
        invitation_tracking,
        recaptcha,
        type: 1,
      })

      track({ event: "companyInquiryCreate", payload: { name: companyName } })
      setIsSuccesfullySubmitted(true)
    } catch (err) {
      const { code, field_errors: fieldErrors } = err.response.data
      if (code === "validation_error") {
        setValidationErrors(validationErrors => ({
          ...validationErrors,
          fromAPI: generateFieldNamesForValidation(fieldErrors),
        }))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getFieldErrorMessage = field => {
    if (validationErrors.required.includes(field)) {
      return requiredErrorMessage[locale]
    }

    if (validationErrors.isEmailInvalid && field === "email") {
      return emailErrorMessage[locale]
    }

    if (validationErrors.fromAPI.includes(field)) {
      return APIErrorMessage[locale]
    }
  }

  if (isSuccesfullySubmitted) {
    return (
      <section className="py-9">
        <div className="container"> {message[locale]}</div>
      </section>
    )
  }

  return (
    <section className="py-9" id={anchor_id}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h3 className="section-title">{title[locale]}</h3>
            <div className="lead mb-5">{subTitle[locale]}</div>
          </div>
          <div className="col-lg-6">
            <form onSubmit={handleSubmit}>
              {fields.map(field => (
                <div key={field} className="form-group">
                  <input
                    type="text"
                    name={field}
                    className="form-control"
                    placeholder={placeholder[locale][field]}
                    onChange={e =>
                      setValues(values => ({
                        ...values,
                        [field]: e.target.value,
                      }))
                    }
                    value={values[field]}
                  />

                  {getFieldErrorMessage(field) && (
                    <p className="text-danger">{getFieldErrorMessage(field)}</p>
                  )}
                </div>
              ))}
              <button
                disabled={isLoading}
                className="cta"
                type="submit"
                id="button-addon2"
              >
                {button[locale]}{" "}
                {isLoading && <FontAwesomeIcon icon={faCircleNotch} spin />}
              </button>
              <div className="text-muted small my-2">{buttonHint[locale]}</div>
              <ReCAPTCHA
                sitekey={config.google.recaptcha.key}
                onChange={recaptcha =>
                  setValues(values => ({ ...values, recaptcha }))
                }
              />
              {getFieldErrorMessage("recaptcha") && (
                <p className="text-danger">
                  {getFieldErrorMessage("recaptcha")}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
