import React from "react"

const WhiteSpace = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const { size } = primary

  return <div className={`d-block my-${size}`} />
}

export default WhiteSpace
