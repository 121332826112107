import React from "react"

const Divider = ({ slice }) => {
  if (!slice) {
    return null
  }
  return <div className="border-bottom w-100" />
}

export default Divider
