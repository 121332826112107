import React, { useState } from "react"
import moLoop from "../assets/mo-loop.mp4"
import cn from "classnames"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

typeof window !== "undefined" &&
  import("lazysizes/plugins/unveilhooks/ls.unveilhooks")

const FaqSection = ({ faq, images }) => {
  const [videoIndex, setVideoIndex] = useState(null)
  return (
    <section className="py-7 text-center d-none d-md-block">
      <div className="container">
        <h2 className="section-title text-center mb-5">{faq.title}</h2>
        <div className="row justify-content-center mb-6">
          <div className="col-lg-10">
            <div className="tab-content bd-primary" id="faqTabContent">
              <div
                className={cn("tab-pane", {
                  "active show": videoIndex === null,
                })}
                id="panelLoop"
                role="tabpanel"
              >
                <div className="embed-responsive embed-responsive-16by9">
                  <video
                    src={moLoop}
                    className="lazyload"
                    preload="none"
                    data-autoplay
                    muted
                    loop
                  />
                </div>
              </div>
              <div
                className={cn("tab-pane", {
                  "active show": videoIndex !== null,
                })}
                role="tabpanel"
                id="panelVimeo"
              >
                <div className="embed-responsive embed-responsive-16by9 ">
                  {videoIndex !== null && (
                    <iframe
                      src={`https://player.vimeo.com/video/${faq.videos[videoIndex].video}?autoplay=1`}
                      className="lazyload"
                      allow="autoplay; fullscreen"
                    />
                  )}
                </div>
              </div>
            </div>
            <ul
              className="list-unstyled d-flex faq-tabs m-0 nav flex-nowrap"
              id="faqTabs"
              role="tablist"
            >
              {faq.videos.map((v, i) => (
                <li key={i} onClick={() => setVideoIndex(i)}>
                  <a
                    className={cn("p-1 text-dark d-block", {
                      "active show": videoIndex === i,
                    })}
                    data-toggle="tab"
                    id={`video${i}Tab`}
                    href="#panelVimeo"
                    role="tab"
                    aria-controls="video1"
                    aria-selected="false"
                  >
                    <small>{v.link}</small>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <h4 className="mb-4">{faq.subtitle}</h4>
        {faq.button && (
          <a className="cta" href={faq.button_url}>
            {faq.button}
          </a>
        )}
      </div>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query FaqQuery {
        shapeThree: file(name: { eq: "shape-3" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const shapeThree = data.shapeThree.childImageSharp.fluid

      return (
        <BackgroundImage
          Tag="section"
          className="faq-teaser"
          fluid={shapeThree}
        >
          <FaqSection images={{ shapeThree }} {...props} />
        </BackgroundImage>
      )
    }}
  />
)
