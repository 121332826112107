import React from "react"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

export default function Intergations({ slice }) {
  const { primary, items } = slice
  const { title } = primary
  return (
    <section className="py-6">
      <div className="container">
        {title?.raw[0] && (
          <h2 className="section-title text-center">
            {RichText.asText(title.raw)}
          </h2>
        )}
        <div className="row justify-content-center">
          {items.map((item, i) => (
            <div key={i} className="my-3 col-md-6 col-lg-3 text-center">
              <div>
                <img
                  alt={RichText.asText(item.title.raw)}
                  src={item.logo.url}
                  width="240"
                  className="img-fluid"
                />
              </div>
              {item.link ? (
                <Link to={item.link}>
                  {item.link_text}
                  <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                </Link>
              ) : (
                <span>{RichText.asText(item.title)}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
