import React from "react"
import classNames from "classnames"
import PartnersSection from "../components/partners-section"

const PartnersCarousel = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { partners_carousel_title, title_is_bold } = primary
  return (
    <section className="py-6 text-center">
      <div className="container">
        <div
          className={classNames(
            title_is_bold ? "h3 section-title" : "text-muted mb-4 mb-lg-5",
          )}
        >
          {partners_carousel_title || primary.title}
        </div>
        <PartnersSection partners={items} />
      </div>
    </section>
  )
}

export default PartnersCarousel
