import React, { Fragment, useState, useEffect, useCallback } from "react"
import ReactSelect from "react-select"
import { config } from "../utils/config"
import { camelCase, mapKeys, pickBy, size, isEmpty } from "lodash"
import axios from "axios"
import {
  isEmailValid,
  getUtmParams,
  getReferralUserId,
  validateFullName,
  showNotification,
} from "../utils/helpers"
import track from "../utils/track"
import { useDebouncedState } from "../hooks/use-debounced-state"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import ReCAPTCHA from "react-google-recaptcha"
import RecruiterFormInputField from "./recruiter-form-input-field"
import { APIErrorMessageRecruiterSignup } from "../utils/constants"

const title = {
  "en-us": "Sign-Up Now",
  "de-de": "Jetzt Registrieren",
}

export const existingUserErrorMessage = {
  "en-us": (
    <>
      <p className="mb-1">
        It seems this email or the company name already exists in our system. If
        you are trying to log and not sign up, please use this link instead
        <a className="mx-1 text-light" href="http://my.moberries.com/login">
          http://my.moberries.com/login
        </a>
      </p>
      <p className="mb-1">
        If you have mistyped and feel stuck, please refresh the page and try the
        registration again.
      </p>
      <p className="mb-1">
        If it doesn't solve your issue, please reach out to us via
        partners@moberries.com
      </p>
    </>
  ),
  "de-de": (
    <>
      <p className="mb-1">
        Es scheint, dass diese E-Mail order der Name des Unternehmens bereits in
        unserem System vorhanden ist. Wenn Sie versuchen, sich anzumelden,
        anstatt sich zu registrieren, verwenden Sie bitte stattdessen diesen
        Link:
        <a className="mx-1 text-light" href="http://my.moberries.com/login">
          http://my.moberries.com/login
        </a>
      </p>
      <p className="mb-1">
        Wenn Sie sich vertippt haben und nicht weiterkommen, aktualisieren Sie
        bitte die Seite und versuchen Sie die Anmeldung erneut.
      </p>
      <p className="mb-1">
        Wenn Ihr Problem dadurch nicht gelöst wird, wenden Sie sich bitte an uns
        unter partners@moberries.com
      </p>
    </>
  ),
}

const btnTxts = {
  company: {
    "en-us": (
      <span>
        REGISTER AS
        <br /> COMPANY
      </span>
    ),
    "de-de": "Als Unternehmen registrieren",
  },
  default: {
    "en-us": "SIGN UP",
    "de-de": "Jetzt registrieren",
  },
}

const noteTxts = {
  company: {
    "en-us": (
      <span>
        Are you looking for a job?&nbsp;
        <a href={`${config.moberries.candidateAppUrl}/signup`}>Sign up here</a>
      </span>
    ),
    "de-de": (
      <span>
        Suchst Du einen Job?&nbsp;
        <a href={`${config.moberries.candidateAppUrl}/signup`}>
          Melde Dich hier an
        </a>
      </span>
    ),
  },
  default: {
    "en-us": null,
    "de-de": null,
  },
}

const placeholders = {
  company: {
    "en-us": {
      firstName: "First Name",
      lastName: "Last Name",
      email: "E-mail",
      companyName: "Company Name",
      phone: "Phone Number",
      location: "Location",
    },
    "de-de": {
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      companyName: "Firmenname",
      phone: "Telefonnummer",
      location: "Standort",
    },
  },
  default: {
    "en-us": {
      firstName: "First Name",
      lastName: "Last Name",
      email: "E-mail",
      companyName: "Company Name",
      phone: "Phone Number",
      location: "Location",
    },
    "de-de": {
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      companyName: "Agenturname",
      phone: "Telefonnummer",
      location: "Standort",
    },
  },
}

export const requiredErrorMessage = {
  "en-us": "Value required",
  "de-de": "Eingabe ist erforderlich.",
}

export const emailErrorMessage = {
  "en-us": "Value should be a valid email.",
  "de-de": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
}

export const APIErrorMessage = {
  "en-us": "Please check your input.",
  "de-de": "Bitte Eingabe überprüfen.",
}

const fields = [
  "firstName",
  "lastName",
  "email",
  "companyName",
  "phone",
  "location",
]

const mapFieldNames = value => {
  const mapKeyTo = {
    first_name: "firstName",
    last_name: "lastName",
    name: "companyName",
    city: "location",
  }

  return mapKeys(value, (_, key) => {
    if (fields.includes(key)) return key
    if (mapKeyTo[key]) return mapKeyTo[key]
    return key
  })
}

export const generateFieldNamesForValidation = serverFieldErrors => {
  const keys = Object.keys(mapFieldNames(serverFieldErrors))
  const fieldNames = keys.map(i => camelCase(i))
  return fieldNames
}

export const getValidationErrors = values => {
  const missingFields = Object.keys(pickBy(values, isEmpty))
  return {
    required: missingFields,
    isEmailInvalid: !isEmailValid(values.email),
    isFullNameInvalid: !validateFullName(values.fullName),
  }
}

const initialValidationErrors = {
  required: [],
  isEmailInvalid: false,
  fromAPI: [],
}

const RecruiterSignupForm = ({
  locale,
  type,
  form_title,
  setIsSuccesfullySubmitted,
}) => {
  const [values, setValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    phone: "",
    location: "",
    recaptcha: "",
  })
  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors,
  )
  const [isLoading, setIsLoading] = useState(false)
  const placeholder = placeholders[type] || placeholders.default
  const btnTxt = btnTxts[type] || btnTxts.default
  const noteTxt = noteTxts[type] || noteTxts.default

  const {
    state: citySearch,
    debouncedState: debouncedCitySearch,
    setState: setCitySearch,
  } = useDebouncedState("")

  const [cityResults, setCityResults] = useState([])

  const handleSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setValidationErrors(initialValidationErrors)

    const { required, isEmailInvalid } = getValidationErrors(values)

    if (size(required) > 0 || isEmailInvalid) {
      setValidationErrors(validationErrors => ({
        ...validationErrors,
        required,
        isEmailInvalid,
      }))
      setIsLoading(false)
      return
    }

    let invitation_tracking

    const referralInviteUserId = getReferralUserId()
    const utmParams = getUtmParams()

    if (referralInviteUserId) {
      invitation_tracking = { userFrom: referralInviteUserId }
    }

    if (utmParams) {
      invitation_tracking = { ...invitation_tracking, ...utmParams }
    }
    const {
      firstName,
      lastName,
      email,
      companyName,
      phone,
      location,
      recaptcha,
    } = values

    try {
      const url = `${config.moberries.api.url}${
        type === "company" ? "companies" : "recruiter-companies"
      }/`
      await axios.post(url, {
        email,
        first_name: firstName,
        last_name: lastName,
        name: companyName,
        phone,
        city: { place_id: location },
        invitation_tracking,
        recaptcha,
        affiliate_partner: type === "affiliate",
        type: type === "affiliate" ? 0 : 1,
      })

      track({ event: "partnerInquiryCreate", payload: { name: companyName } })
      setIsSuccesfullySubmitted(true)
    } catch (err) {
      const { code, field_errors: fieldErrors } = err.response.data
      if (code === "validation_error") {
        setValidationErrors(validationErrors => ({
          ...validationErrors,
          fromAPI: generateFieldNamesForValidation(fieldErrors),
        }))
        if (fieldErrors.email?.find(msgs => msgs.code === "email_not_unique")) {
          showNotification({
            type: "danger",
            message: existingUserErrorMessage[locale],
          })
        }
      }
    } finally {
      setIsLoading(false)
      setValues({
        email: "",
        firstName: "",
        lastName: "",
        companyName: "",
        phone: "",
        location: "",
        recaptcha: "",
      })
    }
  }

  const getCity = useCallback(async () => {
    if (debouncedCitySearch.length) {
      const { data: { results: citySearchResult = [] } = {} } = await axios.get(
        `${config.moberries.api.url}cities/`,
        {
          params: {
            search: debouncedCitySearch,
          },
        },
      )
      setCityResults(citySearchResult)
    }
  }, [debouncedCitySearch])

  useEffect(() => {
    getCity()
  }, [getCity])

  const getFieldErrorMessage = field => {
    if (validationErrors.required.includes(field)) {
      return requiredErrorMessage[locale]
    }

    if (validationErrors.isEmailInvalid && field === "email") {
      return emailErrorMessage[locale]
    }

    if (validationErrors.fromAPI.includes(field)) {
      const errMsg = APIErrorMessageRecruiterSignup[locale][field]
      return errMsg || APIErrorMessageRecruiterSignup[locale].default
    }
  }

  const onChangeHandler = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    })
  }

  return (
    <Fragment>
      <div className="p-4 ml-2">
        <div>
          <h3 className="section-title">{form_title || title[locale]}</h3>
          <form onSubmit={handleSubmit}>
            <RecruiterFormInputField
              field="companyName"
              placeholder={placeholder[locale].companyName}
              changeHandler={onChangeHandler}
              value={values.companyName}
              getFieldErrorMessage={getFieldErrorMessage}
            />

            <RecruiterFormInputField
              field={{ first: "firstName", second: "lastName" }}
              placeholder={{
                first: placeholder[locale].firstName,
                second: placeholder[locale].lastName,
              }}
              changeHandler={onChangeHandler}
              value={{ first: values.firstName, second: values.lastName }}
              getFieldErrorMessage={getFieldErrorMessage}
              doubleFields
            />

            <RecruiterFormInputField
              field="email"
              placeholder={placeholder[locale].email}
              changeHandler={onChangeHandler}
              value={values.email}
              getFieldErrorMessage={getFieldErrorMessage}
            />

            <RecruiterFormInputField
              field="phone"
              placeholder={placeholder[locale].phone}
              changeHandler={onChangeHandler}
              value={values.phone}
              getFieldErrorMessage={getFieldErrorMessage}
            />

            <div className="row-md form-group">
              <ReactSelect
                name="location"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    boxShadow: "none",
                    borderRadius: "0.5rem",
                    borderColor: state.isFocused ? "red" : "lightGrey",
                    "&:hover": { borderColor: "lightGrey" },
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: "rgba(171, 179, 187)",
                  }),
                }}
                placeholder={placeholder[locale].location}
                className="text-dark text-left"
                options={cityResults}
                onInputChange={e => setCitySearch(e)}
                onChange={e =>
                  setValues(values => ({
                    ...values,
                    location: e?.place_id,
                  }))
                }
                backspaceRemovesValue={false}
                isClearable
                isSearchable
                inputValue={citySearch}
                getOptionValue={option => option.place_id}
                getOptionLabel={option =>
                  `${option.name}, ${option.country.name}`
                }
              />
              {getFieldErrorMessage("location") && (
                <p className="text-danger p-0">
                  {getFieldErrorMessage("location")}
                </p>
              )}
            </div>

            <ReCAPTCHA
              hl={locale === "en-us" ? "en" : "de"}
              sitekey={config.google.recaptcha.key}
              onChange={recaptcha => onChangeHandler("recaptcha", recaptcha)}
              className="google-recaptcha"
            />
            {getFieldErrorMessage("recaptcha") && (
              <p className="text-danger mb-0">
                {getFieldErrorMessage("recaptcha")}
              </p>
            )}

            <button
              disabled={isLoading}
              className="cta mt-3"
              type="submit"
              id="button-addon2"
              data-toggle="modal"
              data-target="createInquiryModal"
            >
              {btnTxt[locale]}
              {isLoading && <FontAwesomeIcon icon={faCircleNotch} spin />}
            </button>

            <p className="cta-note mt-2">{noteTxt[locale]}</p>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default RecruiterSignupForm
