import React from "react"
import NetworkSection from "../components/network-section"

const HomepageNetworks = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  return (
    <NetworkSection
      network={{
        image: primary.image.url,
        title: primary.network_title || primary.title,
        sector_1: primary.sector1,
        sector_2: primary.sector2,
        sector_3: primary.sector3,
      }}
    />
  )
}

export default HomepageNetworks
