import React from "react"
import FeatureSection from "../components/feature-section"

const Features = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const { features_title, body1, button_text, button_link, image } = primary
  return (
    <FeatureSection
      title={features_title || primary.title}
      text={body1}
      buttonText={button_text}
      buttonUrl={button_link.url}
      image={image.localFile?.childImageSharp?.fluid || image.fluid}
      imgUrl={image.url}
    />
  )
}

export default Features
