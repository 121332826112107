import React from "react"
import FaqRecruitersSection from "../components/faq-recruiters-section"

const FAQ = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { recruiters_faq_title } = primary
  return (
    <FaqRecruitersSection
      title={recruiters_faq_title || primary.title}
      faq={{ items }}
    />
  )
}

export default FAQ
