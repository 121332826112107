import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const Screenshots = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle, anchor_id } = primary
  return (
    <section className="py-6 text-center" id={anchor_id}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {title && (
              <h2 className="section-title">{RichText.asText(title)}</h2>
            )}
            {subtitle?.raw[0] && (
              <div className="lead mb-5">
                {RichText.render(subtitle.raw, linkResolver, htmlSerializer)}
              </div>
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          {items.map((item, i) => (
            <div key={i} className="col-md text-center">
              <div
                className="my-4 my-lg-5 screen-wrapper position-relative"
                data-number={i + 1}
              >
                <img
                  data-sal="fade"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="1500"
                  className="img-fluid"
                  alt="MoBerries Benefit"
                  src={item?.image?.url}
                />
              </div>
              {item.title?.raw[0] &&
                RichText.render(item.title.raw, linkResolver, htmlSerializer)}
              <div className="mb-4">
                {item.text?.raw[0] &&
                  RichText.render(item.text.raw, linkResolver, htmlSerializer)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Screenshots
