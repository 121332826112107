import React from "react"
import networkImg from "../assets/network.svg"

const NetworkSection = ({ network }) => {
  return (
    <section className="py-7">
      <div className="container">
        <h2 className="mb-5 section-title text-center">{network.title}</h2>
        <div className="row no-gutters justify-content-center">
          <div className="col-lg-10">
            <img
              className="lazy img-fluid"
              src={network.image || networkImg}
              alt="MoBerries Network"
            />
          </div>
        </div>
        <div className="row justify-content-around d-none d-md-flex">
          <div className="col-3">
            {network.sector_1 && <div className="border-right py-4" />}
          </div>
          <div className="col-2 p-0">
            {network.sector_2 && <div className="border-right py-4" />}
          </div>
          <div className="col-3 offset-2">
            {network.sector_3 && <div className="border-left py-4" />}
          </div>
        </div>
        <div className="row justify-content-around d-none d-md-flex">
          <div className="col-3 text-md-right pt-2">
            <h4>{network.sector_1}</h4>
          </div>
          <div className="col-3 text-center pt-2">
            <h4>{network.sector_2}</h4>
          </div>
          <div className="col-3 text-md-left pt-2">
            <h4>{network.sector_3}</h4>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NetworkSection
