import React from "react"
import { RichText } from "prismic-reactjs"
import Slider from "react-slick"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

const settingsSliderCarousel = {
  infinite: true,
  slidesToShow: 6,
  dots: false,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  variableWidth: true,
  draggable: false,
  useTransform: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}

const LogoCarousel = ({ slice }) => {
  const { primary, items } = slice
  const { title, subtitle_carousel, anchor_id } = primary
  return (
    <section className="py-6" id={anchor_id}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            {title?.raw[0] && (
              <h2 className="section-title">{RichText.asText(title.raw)}</h2>
            )}
            {subtitle_carousel && (
              <div className="lead mb-5">{subtitle_carousel}</div>
            )}
          </div>
        </div>
        <div className="logo-slider slick-initialized slick-slider">
          <div className="slick-list">
            <div className="slick-track">
              <Slider {...settingsSliderCarousel}>
                {items.map((item, i) => (
                  <div
                    key={i}
                    className="logo-wrapper text-center px-4 "
                    tabIndex="-1"
                  >
                    <img
                      className="lazyload img-fluid"
                      data-src={item?.logo?.url}
                      alt="Logo"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LogoCarousel
