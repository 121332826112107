import React, { Fragment } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { localizePath } from "../utils/helpers"
import WavesImage from "../components/waves-image"
import heroB2BHome from "../assets/b2b-hero.svg"
import heroB2CHome from "../assets/b2c-hero.svg"

const HeroSection = ({ slice, locale, path }) => {
  if (!slice) {
    return null
  }
  const isHomePage = path === "/"
  const { primary } = slice
  const {
    button_text,
    link_text,
    background_image,
    hero_subtitle,
    theme,
    hero_title,
    has_waves_cutout,
    link,
  } = primary
  const bgImg = background_image.url || (isHomePage ? heroB2BHome : heroB2CHome)

  return (
    <Fragment>
      <section className={classNames({ "bg-dark": theme === "dark" }, "py-7")}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 text-center text-md-left">
              <h1 className={classNames({ "text-white": theme === "dark" })}>
                {hero_title || primary.title}
              </h1>
              <div
                className={classNames(
                  { "text-muted": theme === "dark" },
                  "lead pt-2 pb-3",
                )}
              >
                {hero_subtitle || primary.subtitle}
              </div>
              <div className="mb-3">
                <a className="cta" href={link.url}>
                  {button_text}
                </a>
              </div>
              <Link
                className="text-muted"
                to={localizePath(isHomePage ? "/candidates" : "/", locale)}
              >
                <u>{link_text}</u>
              </Link>
            </div>
            <div className="col-md pt-5 pt-md-0">
              <img src={bgImg} alt="MoBerries Hero" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      {has_waves_cutout && (
        <WavesImage page={theme === "dark" ? "home" : "page"} />
      )}
    </Fragment>
  )
}

export default HeroSection
