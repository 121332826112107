let queue = []

export default function(event) {
  const { dataLayer } = window

  if (dataLayer) {
    if (queue.length > 0) {
      queue.forEach(item => {
        dataLayer.push(item)
      })
      queue = []
    }

    dataLayer.push(event)
  } else {
    queue.push(event)
  }
}
