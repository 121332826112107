import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import Img from "gatsby-image"

export default ({ title, text, buttonText, buttonUrl, image, imgUrl }) => {
  return (
    <section className="py-8">
      <div className="container">
        <div className="row no-gutters align-items-center">
          <div className="col-md mb-4 mb-md-0">
            <h2 className="section-title">{title}</h2>
            {RichText.render(text?.raw || [], linkResolver, htmlSerializer)}
            <a className="cta" href={buttonUrl}>
              {buttonText}
            </a>
          </div>
          <div className="col-md">
            {image ? (
              <Img
                fluid={image}
                data-sal="slide-up"
                data-sal-easing="ease-in-out"
                data-sal-duration="1500"
              />
            ) : (
              <img src={imgUrl} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
