import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const Pool = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle, anchor_id } = primary
  return (
    <section className="py-6" id={anchor_id}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            {title?.raw[0] && (
              <h2 className="section-title">{RichText.asText(title.raw)}</h2>
            )}
            {subtitle?.raw[0] && (
              <div className="lead mb-5">
                {RichText.render(subtitle.raw, linkResolver, htmlSerializer)}
              </div>
            )}
          </div>
        </div>
        <div className="row text-center justify-content-center">
          {items.map((item, i) => (
            <div key={i} className="my-3 col-md-6 col-lg-4 col-xl-3">
              {item.linked ? (
                <a href={item.linked}>
                  <img
                    alt="Partner"
                    className="partner-logo"
                    src={item.image.url}
                  />
                </a>
              ) : (
                <img
                  alt="Partner"
                  className="partner-logo"
                  src={item.image.url}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Pool
