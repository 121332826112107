import React, { Fragment } from "react"

const RecruiterFormInputField = ({
  field,
  value,
  changeHandler,
  placeholder,
  getFieldErrorMessage,
  doubleFields,
}) => {
  return (
    <Fragment>
      {doubleFields ? (
        <div className="row">
          <div className="col-md-6 mb-3">
            <input
              type="text"
              name={field.first}
              className="form-control"
              placeholder={placeholder.first}
              onChange={e => changeHandler(field.first, e.target.value)}
              value={value.first}
            />

            {getFieldErrorMessage(field.first) && (
              <span className="text-danger">
                {getFieldErrorMessage(field.first)}
              </span>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <input
              type="text"
              name={field.second}
              className="form-control"
              placeholder={placeholder.second}
              onChange={e => changeHandler(field.second, e.target.value)}
              value={value.second}
            />
            {getFieldErrorMessage(field.second) && (
              <span className="text-danger">
                {getFieldErrorMessage(field.second)}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="row-md form-group">
          <input
            type="text"
            name={field}
            className="form-control mb-md-0"
            placeholder={placeholder}
            onChange={e => changeHandler(field, e.target.value)}
            value={value}
          />

          {getFieldErrorMessage(field) && (
            <span className="text-danger">{getFieldErrorMessage(field)}</span>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default RecruiterFormInputField
