import React, { Fragment } from "react"
import Hero from "../slices/hero"
import PageHead from "../slices/page-head"
import Bubbles from "../slices/bubbles-section"
import Screenshots from "../slices/screenshots"
import TiledContent from "../slices/tiled-content"
import CustomTiled from "../slices/custom-tiled"
import SingleColumn from "../slices/single-column"
import Pool from "../slices/pool"
import Plans from "../slices/plans"
import ContactUs from "../slices/contact-us"
import CtaCentered from "../slices/cta-centered"
import ContentBlog from "../slices/content-blog"
import Integrations from "../slices/integrations"
import Testimonials from "../slices/testimonials"
import LogoCourusel from "../slices/logo-carousel"
import WhiteSpace from "../slices/white-space"
import OverviewSection from "../slices/overview-section"
import BlockQuote from "../slices/block-quote"
import LinkBox from "../slices/link-box"
import InfoBox from "../slices/info-box"
import FlowChart from "../slices/flow-chart"
import HeroSection from "../slices/hero-section"
import PartnersCarousel from "../slices/partners-carousel"
import InfoWithIcons from "../slices/info-with-icons"
import HomePageIntegrations from "../slices/homepage-integrations"
import TestimonialsCarousel from "../slices/testimonials-carousel"
import HomepageNetworks from "../slices/network-slice"
import PricingCards from "../slices/pricing-cards"
import FAQVideo from "../slices/faq-video"
import Features from "../slices/features"
import Process from "../slices/process"
import FAQSlice from "../slices/faq"
import HeroSectionRecruiter from "../slices/hero-section-recruiter"
import Divider from "../slices/divider"
import ReducedSignupForm from "./reduced-form"
import HeroSectionCandidate from "../slices/hero-section-candidate"
import ContinueReading from "../slices/continue-reading"

const shouldAddWrapper = (type, shouldWrapDoc) => {
  const blockedTypes = [
    "divider",
    "hero_section",
    "hero_section_recruiter",
    "hero_section_candidate",
    "partners_carousel",
    "info_with_icons",
    "homepage_integrations",
    "testimonial_carousel",
    "network",
    "pricing_cards",
    "faq_video",
    "features",
    "process",
    "faq",
  ]
  if (blockedTypes.includes(type)) {
    return false
  }
  if (shouldWrapDoc) return true
  return false
}

const withWrapper = (slice, type, shouldWrapDoc, key) => {
  if (shouldAddWrapper(type, shouldWrapDoc))
    return (
      <div className="d-flex justify-content-center" key={key}>
        <div className="w-100">{slice}</div>
      </div>
    )

  return slice
}

const SliceZone = ({ allSlices, locale, path, shouldWrapDoc }) => {
  const slice = allSlices.map((s, i) => {
    switch (s.slice_type) {
      // These are the API IDs of the slices
      case "hero":
        return withWrapper(
          <Hero key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "page_head":
        return withWrapper(
          <PageHead key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "bubbles":
        return withWrapper(
          <Bubbles key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "screenshots":
        return withWrapper(
          <Screenshots key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "tiled_content":
        return withWrapper(
          <TiledContent key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "custom_tiled":
        return withWrapper(
          <CustomTiled key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "single_column":
        return withWrapper(
          <SingleColumn key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "pool":
        return withWrapper(
          <Pool key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "plans":
        return withWrapper(
          <Plans key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "contact_us":
        return withWrapper(
          <ContactUs key={i} slice={s} locale={locale} path={path} />,
          s.slice_type,
          path,
          i,
        )
      case "cta":
        return withWrapper(
          <CtaCentered key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "content_blog":
        return withWrapper(
          <ContentBlog key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "integrations":
        return withWrapper(
          <Integrations key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "testimonials":
        return withWrapper(
          <Testimonials key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "logo_carousel":
        return withWrapper(
          <LogoCourusel key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "vertical_space":
        return withWrapper(
          <WhiteSpace key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "overview_section":
        return withWrapper(
          <OverviewSection key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "quote_box":
        return withWrapper(
          <BlockQuote key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "link_box":
        return withWrapper(
          <LinkBox key={i} locale={locale} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "info_box":
        return withWrapper(
          <InfoBox key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "flow_chart":
        return withWrapper(
          <FlowChart key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "hero_section":
        return withWrapper(
          <HeroSection key={i} slice={s} locale={locale} path={path} />,
          s.slice_type,
          path,
          i,
        )
      case "hero_section_candidate":
        return withWrapper(
          <HeroSectionCandidate
            key={i}
            slice={s}
            locale={locale}
            path={path}
          />,
          s.slice_type,
          path,
          i,
        )
      case "partners_carousel":
        return withWrapper(
          <PartnersCarousel key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "info_with_icons":
        return withWrapper(
          <InfoWithIcons key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "homepage_integrations":
        return withWrapper(
          <HomePageIntegrations key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "testimonial_carousel":
        return withWrapper(
          <TestimonialsCarousel key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "network":
        return withWrapper(
          <HomepageNetworks key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "pricing_cards":
        return withWrapper(
          <PricingCards key={i} slice={s} />,
          s.slice_type,
          path,
          i,
        )
      case "faq_video":
        return withWrapper(
          <FAQVideo key={i} slice={s} locale={locale} />,
          s.slice_type,
          path,
          i,
        )
      case "features":
        return withWrapper(
          <Features key={i} slice={s} locale={locale} />,
          s.slice_type,
          path,
          i,
        )
      case "hero_section_recruiter":
        return withWrapper(
          <HeroSectionRecruiter
            key={i}
            slice={s}
            locale={locale}
            type="affiliate"
          />,
          s.slice_type,
          path,
          i,
        )
      case "hero_section_reduced_form":
        return withWrapper(
          <ReducedSignupForm key={i} slice={s} locale={locale} />,
          s.slice_type,
          path,
          i,
        )
      case "hero_section_with_signup_form":
        return withWrapper(
          <HeroSectionRecruiter
            key={i}
            type="company"
            slice={s}
            locale={locale}
          />,
          s.slice_type,
          path,
          i,
        )
      case "process":
        return withWrapper(
          <Process key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "faq":
        return withWrapper(
          <FAQSlice key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "divider":
        return withWrapper(
          <Divider key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      case "continue_reading":
        return withWrapper(
          <ContinueReading locale={locale} key={i} slice={s} />,
          s.slice_type,
          shouldWrapDoc,
          i,
        )
      default:
        return null
    }
  })
  return <Fragment>{slice}</Fragment>
}

export default SliceZone
