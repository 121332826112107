import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const Bubbles = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle_bubbles, anchor_id } = primary
  return (
    <section className="py-6 text-center" id={anchor_id}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            {title?.raw[0] && (
              <h2 className="section-title">{RichText.asText(title.raw)}</h2>
            )}
            {subtitle_bubbles && (
              <div className="lead mb-5">{subtitle_bubbles}</div>
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          {items.map((item, i) => {
            return (
              <div key={i} className="col-md-4 text-center d-flex flex-column">
                <div className="my-3">
                  {item.image?.url && (
                    <img
                      data-sal="fade"
                      data-sal-easing="ease-in-out"
                      data-sal-duration="1500"
                      alt="MoBerries Benefit"
                      height="160"
                      src={item.image.url}
                    />
                  )}
                </div>
                {item.title?.raw[0] &&
                  RichText.render(item.title.raw, linkResolver, htmlSerializer)}
                <div className="mb-4 mb-lg-0">
                  {item.text?.raw[0] &&
                    RichText.render(
                      item.text.raw,
                      linkResolver,
                      htmlSerializer,
                    )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Bubbles
