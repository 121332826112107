import React, { Fragment } from "react"
import classNames from "classnames"
import heroB2BHome from "../assets/b2b-hero.svg"
import JobSearch from "../components/job-search"

const HeroSectionCandidate = ({ slice, locale, path }) => {
  if (!slice) {
    return null
  }

  const { primary } = slice
  const {
    search_label,
    search_placeholder,
    background_image,
    hero_subtitle,
    theme,
    hero_title,
  } = primary
  const bgImg = background_image.url || heroB2BHome

  return (
    <Fragment>
      <section className={classNames({ "bg-dark": theme === "dark" }, "py-7")}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 text-center text-md-left">
              <h1 className={classNames({ "text-white": theme === "dark" })}>
                {hero_title || primary.title}
              </h1>
              <div
                className={classNames(
                  { "text-muted": theme === "dark" },
                  "lead pt-2 pb-3 mb-2",
                )}
              >
                {hero_subtitle || primary.subtitle}
              </div>
              <JobSearch
                label={search_label}
                placeholder={search_placeholder}
              />
            </div>
            <div className="col-md pt-5 pt-md-0">
              <img src={bgImg} alt="MoBerries Hero" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default HeroSectionCandidate
