import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { getPath } from "../utils/gatsby-node-helpers"
import BlogRoll from "../components/blog-roll"

const ContinueReading = ({ slice, locale }) => {
  const { primary } = slice
  const { continue_reading_title, continue_reading_custom_tag } = primary

  return (
    <StaticQuery
      query={graphql`
        query BlogRollCategoriesContinueReading {
          allPrismicBlogPost(
            sort: { fields: first_publication_date, order: DESC }
          ) {
            totalCount
            nodes {
              data {
                custom_tag_group {
                  custom_tag {
                    uid
                    type
                    lang
                  }
                }
                category {
                  uid
                }
                cover {
                  url
                }
                title {
                  text
                }
                title_meta
                body {
                  ... on PrismicBlogPostBodyContentBlog {
                    primary {
                      content_blog {
                        html
                        raw
                        text
                      }
                    }
                    slice_type
                  }
                }
              }
              first_publication_date
              uid
              lang
            }
          }
        }
      `}
      render={data => {
        const posts = data.allPrismicBlogPost.nodes
        const filteredPosts = []

        posts.forEach(node => {
          const postTags = node.data.custom_tag_group
          postTags.forEach(tag => {
            const tagUid = tag.custom_tag?.uid
            if (
              tagUid &&
              continue_reading_custom_tag.uid === tagUid &&
              node.lang === locale
            ) {
              filteredPosts.push(node)
            }
          })
        })

        return (
          <section className="py-6">
            <div className="container">
              <div className="d-flex mb-3">
                <h4 className="m-0 flex-grow-1">{continue_reading_title}</h4>
                <Link
                  to={getPath({
                    docType: "custom_tag",
                    uid: continue_reading_custom_tag.uid,
                    lang: locale,
                  })}
                  className="text-blog"
                >
                  All posts{" "}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="ml-1"
                    size="lg"
                  />
                </Link>
              </div>
              <BlogRoll posts={filteredPosts.slice(0, 3)} />
            </div>
          </section>
        )
      }}
    />
  )
}

export default ContinueReading
