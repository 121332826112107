import React, { useState } from "react"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { RichText } from "prismic-reactjs"
import htmlSerializer from "../utils/html-serializer"
import { linkResolver } from "../utils/linkResolver"

const FaqRecruitersSection = ({ faq, title }) => {
  const [question, setQuestion] = useState(null)

  return (
    <section className="pt-7 pb-9">
      <div className="container">
        <h2 className="section-title text-center">
          {title || "Frequently Asked Questions"}
        </h2>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <ul className="list-group list-group-flush" id="faq">
              {faq.items.map((item, i) => (
                <li key={i} className="list-group-item px-0 bg-transparent p-0">
                  <h5
                    className="clickable d-flex justify-content-between align-items-center mb-0 py-3"
                    role="button"
                    onClick={() => {
                      if (question === i) {
                        return setQuestion(null)
                      }
                      setQuestion(i)
                    }}
                  >
                    <span>{item.question}</span>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </h5>
                  <div
                    className={cn("collapse", {
                      show: question === i,
                    })}
                    id={`q${i}`}
                  >
                    {item.answer?.raw ? (
                      RichText.render(
                        item.answer?.raw || [],
                        linkResolver,
                        htmlSerializer,
                      )
                    ) : (
                      <p>{item.answer}</p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqRecruitersSection
