import React, { useState } from "react"
import RecruiterSuccessfulSignup from "./recruiter-successful-signup"
import RecruiterSignupForm from "./recruiter-signup-form"

const RecruiterSignUp = ({ locale, form_title, type }) => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)

  return (
    <section>
      <div className="container signup-form">
        {isSuccessfullySubmitted ? (
          <RecruiterSuccessfulSignup type={type} locale={locale} />
        ) : (
          <RecruiterSignupForm
            type={type}
            locale={locale}
            form_title={form_title}
            setIsSuccesfullySubmitted={setIsSuccessfullySubmitted}
          />
        )}
      </div>
    </section>
  )
}

export default RecruiterSignUp
