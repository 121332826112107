import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const SingleColumn = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle, anchor_id } = primary
  return (
    <section className="position-relative single-column-wrapper" id={anchor_id}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            {title?.raw[0] && (
              <h2 className="section-title">{RichText.asText(title.raw)}</h2>
            )}
            {subtitle?.raw[0] && (
              <div className="lead mb-5">
                {RichText.render(subtitle.raw, linkResolver, htmlSerializer)}
              </div>
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          {items.map((item, i) => (
            <div key={i} className="col-lg-8 col-xl-7">
              {item.text.raw[0] &&
                RichText.render(item.text.raw, linkResolver, htmlSerializer)}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SingleColumn
