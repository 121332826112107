import React, { useState } from "react"
import AsyncSelect from "react-select/async"
import ReCAPTCHA from "react-google-recaptcha"
import { size } from "lodash"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { config } from "../utils/config"
import track from "../utils/track"
import RecruiterFormInputField from "./recruiter-form-input-field"
import {
  APIErrorMessage,
  emailErrorMessage,
  generateFieldNamesForValidation,
  getValidationErrors,
  requiredErrorMessage,
} from "./recruiter-signup-form"
import RecruiterSuccessfulSignup from "./recruiter-successful-signup"

const btnTxts = {
  "en-us": "SIGN UP",
  "de-de": "Jetzt registrieren",
}

export const fullNameErrorMessage = {
  "en-us": "Value should be a valid full name.",
  "de-de": "Bitte geben Sie einen gültigen vollständigen Namen ein.",
}

const placeholders = {
  "en-us": {
    fullName: "Full Name",
    email: "E-mail",
    companyName: "Company Name",
    phone: "Phone Number",
    location: "Location",
  },
  "de-de": {
    fullName: "Vollständiger Name",
    email: "E-Mail",
    companyName: "Agenturname",
    phone: "Telefonnummer",
    location: "Standort",
  },
}

const initialValidationErrors = {
  required: [],
  isEmailInvalid: false,
  fromAPI: [],
}

const ReducedSignupForm = ({ slice, locale }) => {
  const [allInputsAreVisible, setAllInputsAreVisible] = useState(false)
  const [values, setValues] = useState({
    email: "",
    fullName: "",
    companyName: "",
    phone: "",
    location: "",
    city: "",
  })
  const [isSuccesfullySubmitted, setIsSuccesfullySubmitted] = useState(false)
  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [recaptcha, setRecaptcha] = useState("")

  const { primary } = slice
  const {
    reduced_form_title,
    reduced_form_subtitle,
    source,
    anchor_id,
    image: { url },
  } = primary

  const { email, fullName, companyName, phone, location, city } = values

  const onChangeHandler = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    })
  }

  const getFieldErrorMessage = field => {
    if (validationErrors.required.includes(field)) {
      return requiredErrorMessage[locale]
    }

    if (validationErrors.isFullNameInvalid && field === "fullName") {
      return fullNameErrorMessage[locale]
    }

    if (validationErrors.isEmailInvalid && field === "email") {
      return emailErrorMessage[locale]
    }

    if (validationErrors.fromAPI.includes(field)) {
      return APIErrorMessage[locale]
    }
  }

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    setValidationErrors(initialValidationErrors)

    const { required, isEmailInvalid, isFullNameInvalid } = getValidationErrors(
      values,
    )

    if (size(required) > 0 || isEmailInvalid || isFullNameInvalid) {
      setValidationErrors(validationErrors => ({
        ...validationErrors,
        required,
        isEmailInvalid,
        isFullNameInvalid,
      }))
      setIsLoading(false)
      return
    }

    try {
      const url = `${config.moberries.api.url}companies/`
      const [first_name, last_name] = fullName.split(" ")
      await axios.post(url, {
        email,
        first_name,
        last_name,
        name: companyName,
        phone,
        invitation_tracking: { utm_source: "baas", utm_campaign: source },
        city: { place_id: location },
        recaptcha,
        affiliate_partner: false,
        type: 1,
      })
      // doing this temporarily
      if (window.location.hostname === "www.moberries.com") {
        await axios.post(`https://baas-page.herokuapp.com/`, {
          email,
          first_name,
          last_name,
          company_name: companyName,
          source,
          phone,
          city,
        })
      }

      track({ event: "partnerInquiryCreate", payload: { name: companyName } })
      setIsSuccesfullySubmitted(true)
    } catch (err) {
      const { code, field_errors: fieldErrors } = err.response.data
      if (code === "validation_error") {
        setValidationErrors(validationErrors => ({
          ...validationErrors,
          fromAPI: generateFieldNamesForValidation(fieldErrors),
        }))
      }
    } finally {
      setIsLoading(false)
      setValues({
        email: "",
        fullName: "",
        companyName: "",
        phone: "",
        location: "",
        city: "",
      })
    }
  }

  const loadOptions = async inputValue => {
    const { data: { results: citySearchResult = [] } = {} } = await axios.get(
      `${config.moberries.api.url}cities/`,
      {
        params: {
          search: inputValue,
        },
      },
    )
    return citySearchResult
  }

  return (
    <section id={anchor_id} className="bg-dark pb-8 pt-4 text-white">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center mt-5 mt-md-0">
          {isSuccesfullySubmitted ? (
            <RecruiterSuccessfulSignup type="company" locale={locale} />
          ) : (
            <div className="col-md-5 text-center text-lg-left mr-lg-9">
              <h1>{reduced_form_title}</h1>
              <p className="lead mb-3 reduced-form-subtitle">
                {reduced_form_subtitle}
              </p>
              <form className="reduced-form" onSubmit={onSubmit}>
                <div onClick={() => setAllInputsAreVisible(true)}>
                  <RecruiterFormInputField
                    field="email"
                    placeholder={placeholders[locale]?.email}
                    changeHandler={onChangeHandler}
                    value={email}
                    getFieldErrorMessage={getFieldErrorMessage}
                  />
                </div>
                {allInputsAreVisible && (
                  <div className="mb-3">
                    <RecruiterFormInputField
                      field="fullName"
                      placeholder={placeholders[locale]?.fullName}
                      changeHandler={onChangeHandler}
                      value={fullName}
                      getFieldErrorMessage={getFieldErrorMessage}
                    />
                    <RecruiterFormInputField
                      field="companyName"
                      placeholder={placeholders[locale]?.companyName}
                      changeHandler={onChangeHandler}
                      value={companyName}
                      getFieldErrorMessage={getFieldErrorMessage}
                    />
                    <RecruiterFormInputField
                      field="phone"
                      placeholder={placeholders[locale].phone}
                      changeHandler={onChangeHandler}
                      value={values.phone}
                      getFieldErrorMessage={getFieldErrorMessage}
                    />
                    <div className="row-md form-group">
                      <AsyncSelect
                        name="location"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            boxShadow: "none",
                            borderRadius: "0.5rem",
                            borderColor: state.isFocused ? "red" : "lightGrey",
                            "&:hover": { borderColor: "lightGrey" },
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            color: "rgba(171, 179, 187)",
                          }),
                        }}
                        placeholder={placeholders[locale].location}
                        className="text-dark text-left"
                        loadOptions={loadOptions}
                        onChange={e =>
                          setValues(values => ({
                            ...values,
                            location: e?.place_id,
                            city: e?.name,
                          }))
                        }
                        backspaceRemovesValue={false}
                        isClearable
                        isSearchable
                        getOptionValue={option => option.place_id}
                        getOptionLabel={option =>
                          `${option.name}, ${option.country.name}`
                        }
                      />
                      {getFieldErrorMessage("location") && (
                        <p className="text-danger p-0">
                          {getFieldErrorMessage("location")}
                        </p>
                      )}
                    </div>
                    <ReCAPTCHA
                      hl={locale === "en-us" ? "en" : "de"}
                      sitekey={config.google.recaptcha.key}
                      onChange={recaptcha => setRecaptcha(recaptcha)}
                      className="google-recaptcha"
                    />
                  </div>
                )}
                <button
                  disabled={isLoading}
                  className="cta w-100"
                  type="submit"
                >
                  {btnTxts[locale]}
                  {isLoading && <FontAwesomeIcon icon={faCircleNotch} spin />}
                </button>
              </form>
            </div>
          )}
          <div className="col-md-7 text-center">
            <img src={url} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReducedSignupForm
