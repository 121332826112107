import React from "react"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const CustomTiled = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle, anchor_id } = primary

  return (
    <section className="position-relative" id={anchor_id}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            {title?.raw[0] && (
              <h2 className="section-title">{RichText.asText(title.raw)}</h2>
            )}
            {subtitle?.raw[0] && (
              <div className="lead">
                {RichText.render(subtitle.raw, linkResolver, htmlSerializer)}
              </div>
            )}
          </div>
        </div>

        {items?.map((item, i) => (
          <div
            key={i}
            className={cn("row align-items-center justify-content-center", {
              "flex-row-reverse": item.text_order,
            })}
          >
            <div className="col-lg-5 text-center text-md-left">
              {item.title?.raw[0] && (
                <h4 className="mb-4">{RichText.asText(item.title?.raw)}</h4>
              )}
              {item.text?.raw[0] &&
                RichText.render(item.text.raw, linkResolver, htmlSerializer)}
              {item.button_url && (
                <a className="cta" href={item.button_url}>
                  {item.button_text}
                </a>
              )}
            </div>
            <div className="col-lg-5">
              {item.image?.url && (
                <img
                  data-sal={i % 2 ? "slide-left" : "slide-right"}
                  data-sal-easing="ease-in-out"
                  data-sal-duration="1000"
                  className="img-fluid mb-4 mb-lg-0"
                  src={item.image?.url}
                  alt={item.image?.alt}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default CustomTiled
