import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const confirmationTitles = {
  company: {
    "en-us": "Thank you for your interest",
    "de-de": "Vielen Dank für Ihr Interesse",
  },
  default: {
    "en-us": "Thank you for your interest",
    "de-de": "Vielen Dank für Ihr Interesse",
  },
}

const confirmationBodys = {
  company: {
    "en-us": `Thank you for registering your company! We will contact you shortly to discuss the next steps.`,
    "de-de": `Vielen Dank, dass Sie sich als Unternehmen angemeldet haben. Wir werden uns in Kürze mit Ihnen in Verbindung setzen, um die nächsten Schritte zu besprechen.`,
  },
  default: {
    "en-us": `Thank you for signing up to a recruiter account! Our partner manager
      will contact you shortly to discuss the next steps.`,
    "de-de": `Vielen Dank, dass Sie sich für ein Recruiter-Konto angemeldet haben. Unser Partnermanager wird sich in Kürze mit Ihnen in Verbindung setzen, um die nächsten Schritte zu besprechen.`,
  },
}

const confirmationFooters = {
  company: {
    "en-us": (
      <p>
        If you would like to know more, you can check out our{" "}
        <a
          className="text-info"
          href="https://help.moberries.com/en/collections/2099568-for-companies"
        >
          FAQ
        </a>{" "}
        section.
      </p>
    ),
    "de-de": (
      <p>
        Wenn Sie vorab bereits mehr wissen möchten, besuchen Sie unseren{" "}
        <a
          className="text-info"
          href="https://help.moberries.com/de/collections/2099568-fur-unternehmen"
        >
          FAQ-Bereich.
        </a>
      </p>
    ),
  },
  default: {
    "en-us": (
      <p>
        If you already want to know more, head over to our{" "}
        <a
          className="text-info"
          href="https://help.moberries.com/en/collections/2140789-for-recruiters"
        >
          FAQ
        </a>{" "}
        section.
      </p>
    ),
    "de-de": (
      <p>
        Wenn Sie mehr wissen möchten, besuchen Sie unseren{" "}
        <a
          className="text-info"
          href="https://help.moberries.com/en/collections/2140789-for-recruiters"
        >
          FAQ-Bereich.
        </a>
      </p>
    ),
  },
}

const RecruiterSuccessfulSignup = ({ locale, type }) => {
  const confirmationTitle =
    confirmationTitles[type] || confirmationTitles.default
  const confirmationBody = confirmationBodys[type] || confirmationBodys.default
  const confirmationFooter =
    confirmationFooters[type] || confirmationFooters.default

  return (
    <div className="p-4 ml-2" style={{ maxWidth: "355px" }}>
      <div>
        <FontAwesomeIcon icon={faCheck} size="4x" className="text-info" />
        <h3 className="section-title">{confirmationTitle[locale]}</h3>
        <p>{confirmationBody[locale]}</p>
      </div>
      <p>..........</p>
      <div>{confirmationFooter[locale]}</div>
    </div>
  )
}

export default RecruiterSuccessfulSignup
