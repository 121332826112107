import React from "react"
import RecruiterSignUp from "./recruiter-signup"
import WavesSVG from "../assets/waves.svg"

const HeroSectionRecruiter = ({ hero, locale, type }) => {
  const styles = {}
  if (hero.background_image?.url) {
    styles.backgroundImage = `url(${hero.background_image.url})`
  }

  return (
    <section
      id={hero.anchor_id}
      style={styles}
      className="hero-section-light pt-5 pt-md-0 text-white"
    >
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center mt-5 mt-md-0">
          <div className="col-md-5 text-center text-lg-left mr-lg-9">
            <h1>{hero.title}</h1>
            <div className="lead mb-3">{hero.subtitle}</div>
          </div>
          <div className=" signup-form-column col-md-7 text-center">
            <RecruiterSignUp
              type={type}
              locale={locale}
              form_title={hero.form_title}
            />
          </div>
        </div>
      </div>
      {hero.has_waves_cutout && (
        <img src={WavesSVG} className="recruiter-wave-svg" />
      )}
    </section>
  )
}

export default HeroSectionRecruiter
