import React from "react"
import { RichText } from "prismic-reactjs"

const PageHead = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const { title, subtitle } = primary
  return (
    <>
      <section className="py-7 bg-dark text-center text-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h1 className="mb-4">{RichText.asText(title.raw)}</h1>
              {subtitle?.raw[0] && (
                <p className="lead font-weight-bold">
                  {RichText.asText(subtitle.raw)}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="waves waves-home-lp" />
    </>
  )
}

export default PageHead
