import React from "react"
import HowSection from "../components/how-section"

const Process = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { process_title } = primary
  return (
    <HowSection
      how={{
        title: process_title || primary.title,
        items: items.map(item => ({
          ...item,
          title: item.process_item_title || item.title,
          howto_text: item.process_body,
        })),
      }}
      page="home"
      howImgs={items.map(item => item.image.url)}
    />
  )
}

export default Process
