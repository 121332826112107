import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import quotesImage from "../assets/quotes.png"

const BlockQuote = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const { quote_text } = primary
  return (
    <div className="px-5 py-3">
      <blockquote className="blockquote position-relative py-4">
        <img
          className="position-absolute blockquote-slice-img"
          src={quotesImage}
          alt="blockquote-image"
        />
        <div className="mb-0 pl-4 pt-4">
          {RichText.render(quote_text.raw, linkResolver, htmlSerializer)}
        </div>
      </blockquote>
    </div>
  )
}

export default BlockQuote
