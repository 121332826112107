import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import StartSvg from "../assets/flow-chart-start.svg"
import MiddleSvg from "../assets/flow-chart-middle.svg"
import EndSvg from "../assets/flow-chart-end.svg"

const getClr = (i, totalLength) => {
  if (i === 0) return "#de1843"
  else if (i === totalLength - 1) return "#937f2e"
  return "#4c7d90"
}

const getSvg = (i, totalLength) => {
  if (i === 0) return StartSvg
  else if (i === totalLength - 1) return EndSvg
  return MiddleSvg
}

const SingleFlow = ({ flow, indx, totalLength }) => {
  const Svg = getSvg(indx, totalLength)
  const txtClr = getClr(indx, totalLength)
  const svgMargin = indx === totalLength - 1 ? "-2.5rem" : "0" // the last svg is adding some offset

  return (
    <div className="position-relative mb-4">
      <img
        className="flow-chart-slice-svg"
        style={{ marginRight: svgMargin }}
        src={Svg}
      />
      <div className="flow-chart-slice-container position-absolute h-100 w-100 p-3 d-flex flex-column">
        <p
          className="mt-n2 mb-0 flow-chart-slice-number"
          style={{ color: txtClr }}
        >
          0{indx + 1}
        </p>
        <p className="mb-0 flow-chart-slice-content">{flow}</p>
      </div>
    </div>
  )
}

const FlowChart = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title } = primary
  return (
    <div className="container mb-6">
      <div className="d-flex align-items-center flex-column mb-5">
        {RichText.render(title.raw, linkResolver, htmlSerializer)}
        <div className="flow-chart-slice-border" />
      </div>
      <div className="d-flex flex-wrap justify-content-center">
        {items.map((item, i) => {
          return (
            <SingleFlow
              key={i}
              flow={item.flow}
              indx={i}
              totalLength={items.length}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FlowChart
