import React from "react"
import HeroSectionRecruiterComp from "../components/hero-section-recruiter"

const HeroSectionRecruiter = ({ slice, locale, type }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const {
    recruiter_hero_title,
    recruiter_hero_subtitle,
    background_image,
    has_waves_cutout,
    form_title,
  } = primary

  return (
    <HeroSectionRecruiterComp
      hero={{
        title: recruiter_hero_title || primary.title,
        subtitle: recruiter_hero_subtitle || primary.subtitle,
        has_waves_cutout: has_waves_cutout,
        anchor_id: primary.anchor_id,
        form_title,
        background_image,
      }}
      type={type}
      locale={locale}
    />
  )
}

export default HeroSectionRecruiter
