import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const JobSearch = ({ label, placeholder }) => {
  const [search, setSearch] = useState("")

  const searchJobSubmitHandler = e => {
    e.preventDefault()

    if (search) {
      window.location.replace(
        `https://jobs.moberries.com/jobs?q=${encodeURIComponent(search)}`,
      )
    }
  }

  return (
    <>
      <label htmlFor="jobSearchInput" className="form-label lead">
        {label}
      </label>
      <form className="d-flex w-75" onSubmit={searchJobSubmitHandler}>
        <input
          id="jobSearchInput"
          className="form-control mr-2 bg-light"
          type="text"
          value={search}
          placeholder={placeholder}
          onChange={e => setSearch(e.target.value)}
        />
        <button type="submit" className="btn btn-light border-success">
          <FontAwesomeIcon icon={faSearch} color="green" />
        </button>
      </form>
    </>
  )
}

export default JobSearch
