import React from "react"
import { RichText } from "prismic-reactjs"
import imgInformation from "../assets/information.png"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const InfoBox = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const { description, title } = primary
  return (
    <div className="container py-6 border-top border-bottom d-flex justify-content-center">
      <div className="mr-3">
        <div className="info-box-slice">
          <div className="info-box-slice-icon bg-blog">
            <img alt="info" src={imgInformation} />
          </div>
          <div className="info-box-slice-bar bg-blog" />
          <div className="info-box-slice-end bg-blog" />
        </div>
      </div>
      <div className="mb-n3 mt-8">
        {RichText.render(title.raw, linkResolver, htmlSerializer)}
        <div className="text-muted">
          {RichText.render(description.raw, linkResolver, htmlSerializer)}
        </div>
      </div>
    </div>
  )
}

export default InfoBox
