import React, { Fragment } from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <button type="button" data-role="none" {...props}>
    {children}
  </button>
)

const settingsSliderTestimonials = {
  slidesToShow: 2,
  slidesToScroll: 2,
  arrows: true,
  dots: true,
  draggable: false,
  useTransform: false,

  className: "testimonial-slider my-4 px-md-4 py-4",
  prevArrow: (
    <SlickButtonFix className="slider-prev d-none d-md-inline-block">
      <FontAwesomeIcon icon={faAngleLeft} />
    </SlickButtonFix>
  ),
  nextArrow: (
    <SlickButtonFix className="slider-next d-none d-md-inline-block">
      <FontAwesomeIcon icon={faAngleRight} />
    </SlickButtonFix>
  ),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
const TestimonialsSection = ({ testimonials }) => {
  return (
    <section className="text-center">
      <div className="container">
        <h2 className="section-title">{testimonials.title}</h2>
        <Slider {...settingsSliderTestimonials}>
          {testimonials.items.map((testimonial, i) => (
            <Fragment key={i}>
              <div className="mb-3">
                {testimonial.image.localFile?.childImageSharp ? (
                  <Img
                    fixed={testimonial.image.localFile?.childImageSharp.fixed}
                    className="rounded-circle"
                  />
                ) : (
                  <img
                    src={testimonial.image.url}
                    width="80"
                    className="rounded-circle"
                  />
                )}
              </div>
              <div className="mb-3">
                <strong className="d-block">{testimonial.name}</strong>
                <span className="text-muted">{testimonial.role}</span>
              </div>
              {testimonial.text?.raw &&
                RichText.render(
                  testimonial.text.raw,
                  linkResolver,
                  htmlSerializer,
                )}
              {typeof testimonial.text === "string" && (
                <p>{testimonial.text}</p>
              )}
            </Fragment>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default TestimonialsSection
