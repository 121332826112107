import React, { Fragment } from "react"
import { RichText } from "prismic-reactjs"
import ridge from "../assets/ridge.svg"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const Hero = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const {
    hero_image,
    background_image,
    button,
    has_waves_cutout,
    title,
    subtitle_hero,
    text,
    cta_url,
    anchor_id,
    text_color,
  } = primary
  const styles = {
    backgroundImage: background_image && `url(${background_image.url})`,
    color: `${text_color || "#ffffff"}`,
  }

  return (
    <Fragment>
      <section className="hero position-relative" id={anchor_id} style={styles}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 text-center text-lg-left mb-5 mb-lg-0">
              <h1>{RichText.asText(title.raw || [])}</h1>
              <div className="lead mb-3 font-weight-bold">{subtitle_hero}</div>
              {text?.raw[0] && (
                <div className="hero-text">
                  {RichText.render(
                    text.raw || [],
                    linkResolver,
                    htmlSerializer,
                  )}
                </div>
              )}
              {cta_url && (
                <a className="cta" href={cta_url}>
                  {button}
                </a>
              )}
            </div>
            <div className="col-lg-5">
              {hero_image.url && (
                <img
                  className="img-fluid"
                  alt="MoBerries"
                  src={hero_image.url}
                />
              )}
            </div>
          </div>
        </div>
        {(has_waves_cutout === undefined || has_waves_cutout) && ( // checking for undefined, because previous pages don't have this field
          <div className="hero-ridge">
            <img src={ridge} />
          </div>
        )}
      </section>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.3.1/css/solid.css"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.3.1/css/brands.css"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.3.1/css/fontawesome.css"
        type="text/css"
      />
    </Fragment>
  )
}

export default Hero
