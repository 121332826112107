import React from "react"
import cn from "classnames"
import { isEven } from "../utils/helpers"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const HowSection = ({ how, images, howImgs }) => {
  return (
    <section className="position-relative how-section-slice">
      <div className="shape shape-1">
        <Img fluid={images.shapeOne} />
      </div>
      <div className="shape shape-2">
        <Img fluid={images.shapeTwo} />
      </div>
      <div className="container">
        <h2 className="section-title text-center">{how.title}</h2>
        <div className="howtos">
          {how.items.map((item, i) => (
            <div
              key={i}
              className={cn(
                "row justify-content-center align-items-center my-6",
                {
                  "flex-row-reverse": !isEven(i),
                  "flex-row": isEven(i),
                },
              )}
            >
              <div
                className={cn("col-md-6 text-center text-md-left", {
                  "offset-md-1": !isEven(i),
                })}
              >
                <div className="h2 text-primary font-weight-light">
                  0{i + 1}
                </div>
                <h4>{item.title}</h4>
                {item.howto_text?.raw &&
                  RichText.render(
                    item.howto_text.raw,
                    linkResolver,
                    htmlSerializer,
                  )}
              </div>
              <div
                className={cn("col-md-3 text-center", {
                  "offset-md-1": isEven(i),
                })}
              >
                <img
                  data-sal="slide-up"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="1000"
                  className=" img-fluid mx-5 mx-md-0"
                  src={howImgs[i]}
                  alt={`howto-home${i + 1}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query HowToQuery {
        shapeOne: file(name: { eq: "shape-1@2x" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        shapeTwo: file(name: { eq: "shape-2@2x" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const shapeOne = data.shapeOne.childImageSharp.fluid
      const shapeTwo = data.shapeTwo.childImageSharp.fluid

      return <HowSection images={{ shapeOne, shapeTwo }} {...props} />
    }}
  />
)
