import React from "react"

export default ({ title, subtitle, children, id }) => (
  <section className="py-7 text-center" id={id}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h2 className="section-title text-center">{title}</h2>
          {subtitle && <div className="lead mb-5">{subtitle}</div>}
        </div>
      </div>
      {children}
    </div>
  </section>
)
