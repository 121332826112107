import React from "react"
import { RichText } from "prismic-reactjs"
import htmlSerializer from "../utils/html-serializer"
import Section from "../components/page-section"

const PricingCards = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { pricing_cards_title, button_text, button_url } = primary
  return (
    <Section title={pricing_cards_title || primary.title} id="pricing-section">
      <div className="row pt-4 justify-content-center">
        {items.map(plan => (
          <div className="col-lg-4 mb-2 mb-lg-0" key={plan.plan_name}>
            <div className="card shadow pricing-card h-100">
              <div className="card-header border-0 bg-dark text-white py-3">
                <h3 className="mb-2">{plan.plan_name}</h3>
                <div>{plan.plan_subtitle}</div>
              </div>
              {plan.price_line?.raw[0] && (
                <div className="py-3">
                  {RichText.render(plan.price_line.raw, htmlSerializer)}
                </div>
              )}
              <div className="card-footer border-0 text-left flex-grow-1 py-4">
                {plan.plan_body?.raw[0] &&
                  RichText.render(plan.plan_body.raw, htmlSerializer)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <a className="cta" href={button_url.url}>
          {button_text}
        </a>
      </div>
    </Section>
  )
}

export default PricingCards
