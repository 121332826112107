import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"

export default function Testimonials({ slice }) {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { title, subtitle_testi: subtitle } = primary
  const pairs = items.reduce(function (result, value, index, array) {
    if (index % 2 === 0) result.push(array.slice(index, index + 2))
    return result
  }, [])

  return (
    <section
      className={cn({
        "py-6": title?.raw[0],
      })}
    >
      <div className="container">
        <div
          className={cn("row justify-content-center text-center", {
            "mb-4": title?.raw[0],
          })}
        >
          <div className="col-lg-8">
            {title?.raw[0] && (
              <h2 className="section-title">{RichText.asText(title.raw)}</h2>
            )}
            {subtitle && <div className="lead mb-5">{subtitle}</div>}
          </div>
        </div>
        {pairs.map((pair, i) => (
          <div key={i} className="row justify-content-center">
            {pair.map((item, i) => (
              <div key={i} className="col-lg-8 col-xl-6 mb-4">
                <div className="d-flex p-3 rounded bg-light h-100">
                  <img
                    alt="Testimonial Avatar"
                    src={item.avatar.url}
                    width="104"
                    height="104"
                    className="mb-2 rounded-circle mr-3 flex-shrink-0"
                  />
                  <div className="d-flex flex-column w-100">
                    <div className="mb-2">
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        className="text-muted"
                        size="lg"
                      />
                    </div>
                    {item.text?.raw[0] &&
                      RichText.render(
                        item.text.raw,
                        linkResolver,
                        htmlSerializer,
                      )}
                    <h5 className="mt-auto">{item.name}</h5>
                    <small className="text-muted">{item.position}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}
