import React from "react"
import { Link } from "gatsby"

const LinkBox = ({ slice, locale }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { link_box_title } = primary
  return (
    <div className="link-box-slice bg-light my-3 py-5 px-3">
      <div className="link-box-slice-title w-50 pr-4">
        <h6 className="text-right h4 text-muted">
          {locale === "en-us"
            ? "More articles on"
            : "Weitere Artikel zum Thema"}
        </h6>
        <h4 className="h1 text-right">{link_box_title}</h4>
      </div>
      <div className="link-box-slice-divider" />
      <ul className="mb-0 w-50 p-0 pl-4">
        {items.map((item, i) => {
          const linkTxt = item.link_title || item.link_url.slug

          return (
            <li key={i}>
              <p className="text-dark m-0 font-weight-bold">
                {locale === "en-us" ? "TIP" : "TIPP"}{" "}
                {i < 9 ? `0${i + 1}` : i + 1}
              </p>
              {item.link_url.link_type === "Document" ? (
                <Link to={item.link_url.url}>{linkTxt}</Link>
              ) : (
                <a href={item.link_url.url}>{linkTxt}</a>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default LinkBox
