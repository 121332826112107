import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"

const ContentBlog = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary } = slice
  const { content_blog, text_color } = primary
  const style = {}
  if (text_color) style.color = text_color

  return (
    <div style={style}>
      {content_blog &&
        RichText.render(content_blog.raw, linkResolver, htmlSerializer)}
    </div>
  )
}

export default ContentBlog
