import React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/html-serializer"
import Section from "../components/page-section"

const InfoWithIcons = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { info_with_icons_subtitle, info_with_icons_title } = primary

  return (
    <Section
      title={info_with_icons_title || primary.title}
      subtitle={info_with_icons_subtitle || primary.subtitle || ""}
    >
      <div className="row">
        {items.map(item => (
          <div
            className="col-md d-flex flex-column"
            key={item.info_with_icons_item_title || item.title}
          >
            <div className="my-3">
              <img
                data-sal="fade"
                data-sal-easing="ease-in-out"
                data-sal-duration="1500"
                alt="MoBerries Benefit"
                height="160"
                src={item.button_icon.url}
              />
            </div>
            <h4>{item.info_with_icons_item_title || item.title}</h4>
            {RichText.render(item.info_body?.raw, linkResolver, htmlSerializer)}
            {item.link_url && item.link_text && (
              <p className="mb-4 mt-auto">
                <Link to={item.link_url.url}>{item.link_text}</Link>
              </p>
            )}
          </div>
        ))}
      </div>
    </Section>
  )
}

export default InfoWithIcons
