import React from "react"

const OverviewSection = ({ slice }) => {
  if (!slice) {
    return null
  }
  const { primary, items } = slice
  const { anchor_id, overview_title } = primary
  return (
    <div className="container my-3" id={anchor_id}>
      <div className="d-flex flex-column align-items-center">
        <h2 className="">{overview_title || "Overview"}</h2>
        <div className="overview-slice-border" />
        <ul style={{ listStyle: "none" }} className="m-0 p-0">
          {items.map((item, i) => {
            return (
              <li key={i} className="h5 text-center">
                <a href={`#${item.section_anchor_id}`}>{item.section_title}</a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default OverviewSection
